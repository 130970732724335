import React, { useCallback, useState } from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderOne from "../sections/SliderOne";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import { StaticImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import Axios from 'axios'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const StyledWpPage = styled.div`
  .wp-page {
    &__title {
      text-align: center;
    }

    &__vision {
      color: #527191;
      text-align: center;
    }

    &__mission {
      h3 {
        color: #527191;
      }
    }

    &__valori {
      &__item {
        background-color: #e7c700;
        padding: 2em 4em;
        border-radius: 12px;
        width: 325px;
      }
    }

    &__blue-banner {
      background-color: #e8f0f6;
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }

    &__header {
      background: linear-gradient(
        180deg,
        rgba(246, 234, 199, 0.63) 0%,
        rgba(255, 255, 255, 0) 60%
      );
      mix-blend-mode: darken;

      .intro {
        color: #527191;
      }

      .color-gray {
        color: #6d767b;
      }

      .color-yellow {
        color: #e7c700;
      }

      .content {
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    padding-bottom: 3em;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
    }
  }

  .html_form {
    input:not([type="checkbox"]),
    select {
      height: 55px !important;
    }
    .form-check-input {
      margin-right: 10px;
    }
  }
`;

export default function LavoraConNoi(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [error, setError] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');

    return token
    // Do whatever you want with the token
  }, [executeRecaptcha]);


  const handleSubmit = async e => {
    e.preventDefault()
    const token = await handleReCaptchaVerify()

    const formData = new FormData(e.target)
    formData.append('token', token)

    // const data = new URLSearchParams(formData.entries())

    Axios.post(e.target.action, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then(res => {
        if (res.data === 'OK') {
          navigate('/thank-you-lavora-con-noi');
        } else {
          setError(true)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfXNVMdAAAAAJuGQT2ZOV0AcdsOVcUitc7k79Nb"
      scriptProps={{ defer: true }}
    >
      <Layout helmetTitle="Lavora con noi">
        <StyledWpPage id="page-content" color="transparent">
          <div className="wp-page__header mb-5">
            <div className="wrapper container d-flex align-items-center position-relative justify-content-between">
              <div className="d-flex align-items-center w-100 justify-content-between content pt-5 pt-md-0">
                <div className="d-flex flex-column">
                  <h3 className="color-yellow fw-bold">Lavora con noi</h3>
                  {/* <h4 className="color-gray"></h4> */}
                </div>
                <StaticImage
                  src="../assets/images/testata-conosciamoci-meglio-persone.png"
                  className="img-fluid my-5"
                  alt="Persone"
                  placeholder="blurred"
                  layout="constrained"
                  width={873}
                  height={329}
                />
              </div>
            </div>
          </div>
          <div id="page-content" className="container mx-auto mb-5">
            <h5 className="wp-page__slug mb-5 mt-4">
              <Link to="/" className="fw-bold">
                HOME
              </Link>{" "}
              &gt; <Link to="/lavora-con-noi/">Lavora con noi</Link>
            </h5>
            <h5 className="wp-page__title mb-5 display-6 fw-bold">
              Lavora con noi
            </h5>
            <div className="wp-page__content">
              <h2 className="section__title mb-3">
                In Prestitifaidate.it, le persone sono la chiave del nostro
                successo.
              </h2>
              <p>
                Giorno dopo giorno, costruiamo un ambiente di lavoro dinamico e
                stimolante dove ognuno ha la possibilità di esprimersi e
                realizzarsi. Siamo un team di professionisti motivati e
                intraprendenti, capaci di rispondere al meglio alle esigenze dei
                nostri clienti. La nostra missione è quella di diventare il punto
                di riferimento di pensionati e dipendenti pubblici nel mondo del
                credito.
              </p>
              <p className="mb-5">
                Diamo sempre spazio ai giovani talenti che desiderano mettersi in
                gioco e trovare nuove opportunità di successo. Li aiutiamo a
                crescere combinando percorsi di formazione on the job e impegno
                sul campo.
              </p>
            </div>

            <form
              className="html_form"
              method="POST"
              action={process.env.GATSBY_FORM_CONNECTOR_ENDPOINT}
              onSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-md-6 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    id="first_name"
                    placeholder="Nome*"
                    required="required"
                    maxLength="255"
                  />
                  <label htmlFor="first_name">Nome*</label>
                </div>
                <div className="col-md-6 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    id="last_name"
                    placeholder="Cognome*"
                    required="required"
                    maxLength="255"
                  />
                  <label htmlFor="last_name">Cognome*</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-floating">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    required="required"
                    maxLength="255"
                  />
                  <label htmlFor="email">Email*</label>
                </div>
                <div className="col-md-6 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="telephone"
                    id="telephone"
                    placeholder="Recapito telefonico*"
                    required="required"
                    maxLength="255"
                  />
                  <label htmlFor="telephone">Recapito telefonico*</label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-floating">
                  <input
                    type="city"
                    className="form-control"
                    name="city"
                    id="city"
                    placeholder="Città*"
                    required="required"
                    maxLength="255"
                  />
                  <label htmlFor="city">Città*</label>
                </div>
                <div className="col-md-6 form-floating">
                  <select
                    id="position"
                    name="position"
                    className="form-select"
                    defaultValue=""
                    required="required"
                  >
                    <option value="" disabled>
                      Seleziona qui
                    </option>
                    <option value="AGENTE IN ATTIVITA’ FINANZIARIA (P.IVA)">AGENTE IN ATTIVITA’ FINANZIARIA (P.IVA)</option>
                    <option value="COLLABORATORE (P.IVA)">COLLABORATORE (P.IVA)</option>
                    <option value="IMPIEGATO COMMERCIALE">IMPIEGATO COMMERCIALE</option>
                    <option value="STAFF MANAGER">STAFF MANAGER</option>
                  </select>
                  <label htmlFor="position">Posizione desiderata*</label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 form-floating">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="3"
                    placeholder="Messaggio*"
                    required="required"
                    style={{ height: 150 }}
                  ></textarea>
                  <label htmlFor="message">Messaggio*</label>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label htmlFor="cv" className="form-label">
                    CV*
                  </label>
                  <input
                    className="form-control form-control-lg"
                    id="cv"
                    name="cv"
                    type="file"
                    required="required"
                    accept=".pdf"
                    style={{ fontSize: '1.55rem' }}
                  />
                  <div id="cvHelp" className="form-text">In formato PDF</div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 form-check form-switch">
                  <input
                    className="form-check-input mr-1"
                    type="checkbox"
                    name="checkbox_privacy"
                    id="checkbox_privacy"
                    required="required"
                  />
                  <label className="form-check-label" htmlFor="checkbox_privacy">
                    Accetta la normativa{" "}
                    <a
                      href="https://www.iusprivacy.eu/informativa-privacy-info-1005616530"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy
                    </a>
                  </label>
                </div>
              </div>
              <div className="col-12 text-center">
                <button
                  type="submit"
                  className="btn mt-1 rounded-pill btn-outline-dark text-uppercase"
                >
                  Invia il tuo messaggio
                </button>
              </div>
            </form>
            {
              error && <div className="alert alert-danger mt-5 fw-bold" role="alert">
                Oops, qualcosa è andato storto. Per favore, riprova.
              </div>
            }
            {/* <h2 className="section__title my-3">
            Costruiamo il futuro del credito online. Con te.
          </h2>
          <p>
            In Prestitifaidate.it avrai la possibilità di intraprendere percorsi
            di carriera orientati alla crescita e allo sviluppo professionale.
            Ti diamo l’occasione di cimentarti all'interno di un contesto
            lavorativo giovane e dinamico che dà spazio alle tue ambizioni e
            premia i risultati e la qualità del tuo lavoro.
          </p>
          <p>
            Per questo, ti offriamo:
            <ul>
              <li>un percorso professionale sfidante;</li>
              <li>continue opportunità di crescita;</li>
              <li>lo sviluppo delle tue capacità manageriali;</li>
              <li>un ambiente di professionalità, equità e trasparenza;</li>
            </ul>
          </p> */}
          </div>
        </StyledWpPage>
        <TargetFullBanner
          title="Dipendente pubblico, privato o pensionato?"
          description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
          className="mt-2"
        />
        <DipendentePensionatoPrivato />
        <SliderOne />
        <MetodoPiuVeloce className="mt-2" />
        <CessioneFinanziamenti className="mb-5" />
      </Layout>
    </GoogleReCaptchaProvider>
  );
}
